var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "relative hidden md:block md:overflow-hidden md:max-h-screen"
      },
      [
        _vm._t("images", null, { current: _vm.current }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "absolute bottom-0 left-0 w-full bg-gray-transparent text-gray-200 p-8 opacity-0 hover:opacity-100 transition h-40 lg:h-24",
            on: { mouseover: _vm.clearAutoplay, mouseleave: _vm.startAutoplay }
          },
          [
            _c(
              "div",
              { staticClass: "flex -mx-2" },
              [
                _vm._t("details", null, { current: _vm.current }),
                _vm._v(" "),
                _c("div", { staticClass: "controls w-1/4 px-2 text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "focus:outline-none uppercase text-xs inline-block px-2 py-1 rounded-full bg-gray-900 hover:bg-gray-800",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.clickPrev($event)
                        }
                      }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "fill-current w-4",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 24 24",
                            width: "24",
                            height: "24"
                          }
                        },
                        [
                          _c("path", {
                            staticClass: "heroicon-ui",
                            attrs: {
                              d:
                                "M5.41 11H21a1 1 0 0 1 0 2H5.41l5.3 5.3a1 1 0 0 1-1.42 1.4l-7-7a1 1 0 0 1 0-1.4l7-7a1 1 0 0 1 1.42 1.4L5.4 11z"
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "focus:outline-none uppercase text-xs inline-block px-2 py-1 rounded-full bg-gray-900 hover:bg-gray-800",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.clickNext($event)
                        }
                      }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "fill-current w-4",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 24 24",
                            width: "24",
                            height: "24"
                          }
                        },
                        [
                          _c("path", {
                            staticClass: "heroicon-ui",
                            attrs: {
                              d:
                                "M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z"
                            }
                          })
                        ]
                      )
                    ]
                  )
                ])
              ],
              2
            )
          ]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }