<template>
    <div>
        <div class="relative hidden md:block md:overflow-hidden md:max-h-screen">
            <slot name="images" :current="current"></slot>
            <div class="absolute bottom-0 left-0 w-full bg-gray-transparent text-gray-200 p-8 opacity-0 hover:opacity-100 transition h-40 lg:h-24" @mouseover="clearAutoplay" @mouseleave="startAutoplay">
                <div class="flex -mx-2">
                    <slot name="details" :current="current"></slot>
                    <div class="controls w-1/4 px-2 text-right">
                        <button type="button" @click.prevent="clickPrev" class="focus:outline-none uppercase text-xs inline-block px-2 py-1 rounded-full bg-gray-900 hover:bg-gray-800"><svg class="fill-current w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path class="heroicon-ui" d="M5.41 11H21a1 1 0 0 1 0 2H5.41l5.3 5.3a1 1 0 0 1-1.42 1.4l-7-7a1 1 0 0 1 0-1.4l7-7a1 1 0 0 1 1.42 1.4L5.4 11z"/></svg></button>
                        <button type="button" @click.prevent="clickNext" class="focus:outline-none uppercase text-xs inline-block px-2 py-1 rounded-full bg-gray-900 hover:bg-gray-800"><svg class="fill-current w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path class="heroicon-ui" d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z"/></svg></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => {
            return {
                name: 'Romer Slider',
                current: 0,
                autoplay: true,
                autoplaySpeed: 5000,
                delay: 500,
                // totalSlides: 8
            }
        },
        props: ['totalSlides'],
        methods: {
            changeCurrentWithDelay(next) {
                this.current = false;
                setTimeout(() => {
                    this.current = next;
                }, this.delay);
            },
            increase() {
                this.changeCurrentWithDelay( 
                    (this.current < this.totalSlides - 1) ? this.current + 1 : 0
                );
            },
            decrease() {
                this.changeCurrentWithDelay( 
                    (this.current > 0) ? this.current - 1 : this.totalSlides - 1
                );
            },
            clickPrev() {
                this.clearAutoplay();
                this.decrease();
            },
            clickNext() {
                this.clearAutoplay();
                this.increase();
            },
            clearAutoplay() {
                clearInterval(this.autoplay);
            },
            startAutoplay() {
                this.autoplay = setInterval(() => {
                    this.increase();
                }, this.autoplaySpeed);
            }
        },
        mounted() {
            if (this.autoplay) {
                this.startAutoplay();
            }
        }
    }
</script>
