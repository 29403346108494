import Vue from 'vue';
import RomerSlider from '../components/RomerSlider.vue';

new Vue({
    el: '#app',
    components: {
        RomerSlider,
    },
    data: {
        panelOpen: false,
    },
    methods: {
        togglePanel() {
            this.panelOpen = !this.panelOpen;
        },
        closePanel() {
            this.panelOpen = false;
        }
    }
});